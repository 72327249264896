import { type LoaderFunctionArgs } from '@remix-run/node';
import {
  type MetaFunction,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { $path } from 'remix-routes';

import { EnumsPageSectionType } from '@lp-lib/api-service-client/public';

import config from '../../src/config';
import { fromMediaDTO } from '../../src/utils/api-dto';
import { MediaPickPriorityFHD, MediaUtils } from '../../src/utils/media';
import { slugify } from '../../src/utils/router';
import { MetadataUtils } from '../components/metadata';
import { ProgramDetailsCard } from '../components/Programs/Details/ProgramDetailsCard';
import { AnonProgramDetailsSideAction } from '../components/Programs/Details/ProgramDetailsSideAction';
import { AnonPublicLibraryLayout } from '../components/PublicLibrary/AnonPublicLibraryLayout';
import { AnonPublicLibraryNav } from '../components/PublicLibrary/AnonPublicLibraryNav';
import { PublicLibraryBreadcrumbs } from '../components/PublicLibrary/PublicLibraryBreadcrumbs';
import { fetchActivePrograms } from '../fetches/fetchActivePrograms';
import { useExploreData } from './explore';

export async function loader(action: LoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const programs = await fetchActivePrograms();
  const program = programs.find((p) => p.id === id);
  if (!program) throw new Response('Not Found', { status: 404 });

  return {
    program,
    relatedPrograms: programs.filter((p) => p.id !== program.id),
  };
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return [];

  const { program } = data;

  const mediaFormat = MediaUtils.PickMediaFormat(
    fromMediaDTO(program.basicSettings?.cover?.media),
    {
      priority: MediaPickPriorityFHD,
    }
  );

  return MetadataUtils.Make({
    title: program.name,
    desc: program.basicSettings?.shortDescription,
    url: new URL(
      $path('/explore/programs/:id', { id: program.id }),
      config.app.baseUrl
    ).href,
    image: mediaFormat
      ? {
          url: mediaFormat.url,
          params: {
            width: mediaFormat.width,
            height: mediaFormat.height,
          },
        }
      : 'generic',
  });
};

function Breadcrumbs() {
  const { page } = useExploreData();
  const { program } = useLoaderData<typeof loader>();

  const section = page.sections?.find(
    (section) => section.type === EnumsPageSectionType.PageSectionTypeProgram
  );

  return (
    <PublicLibraryBreadcrumbs
      items={[
        {
          kind: 'link',
          label: 'Explore',
          to: $path('/explore'),
        },
        {
          kind: 'link',
          label: 'Programs',
          to: {
            pathname: $path('/explore'),
            hash: section ? slugify(section.title) : undefined,
          },
        },
        {
          kind: 'noop',
          label: program.name,
        },
      ]}
    />
  );
}

export default function Component() {
  const exploreData = useExploreData();
  const { program, relatedPrograms } = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  return (
    <AnonPublicLibraryLayout
      {...exploreData}
      secondaryNav={
        <AnonPublicLibraryNav
          pageName={exploreData.pageName}
          page={exploreData.page}
          isSectionSelected={(section) =>
            section.type === EnumsPageSectionType.PageSectionTypeProgram
          }
        />
      }
      contentKey={`explore-programs-${program.id}`}
    >
      <ProgramDetailsCard
        program={program}
        anonymous={true}
        relatedPrograms={relatedPrograms}
        breadcrumbs={<Breadcrumbs />}
        sideAction={
          <AnonProgramDetailsSideAction
            program={program}
            product={exploreData.product}
          />
        }
        onClickProgram={(program) =>
          navigate(
            $path('/explore/programs/:id', {
              id: program.id.toString(),
            })
          )
        }
      />
    </AnonPublicLibraryLayout>
  );
}
